// pino.js
/* eslint-disable no-console */
'use strict';
import originalPino from 'pino';

import env from './env.js';

const isProd = process.env.NODE_ENV === 'production';
const isJest = process.env.NODE_ENV === 'test';

const isServer = typeof window === 'undefined';
const isMiddleware = isServer && process.env.NEXT_RUNTIME === 'edge';

const severity = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
  DEBUG: 'DEBUG',
};

const severityMap = {
  error: severity.ERROR,
  warning: severity.WARNING,
  log: severity.INFO,
  info: severity.INFO,
  debug: severity.DEBUG,
};

const mainPino = (() => {
  let lastTimestamp;

  const timestamp = () => {
    const currentTimestamp = Date.now();
    const diff = currentTimestamp - (lastTimestamp || currentTimestamp);
    lastTimestamp = currentTimestamp;

    if (isServer && env.DEBUG_FORMAT === 'json')
      return `, "timestamp": "${currentTimestamp}", "diff": "${diff}ms"`;

    if (isServer) return `,"ms": "${diff}ms"`;

    return `+${diff}ms`;
  };

  let formatters = {};

  if (isServer && env.DEBUG_FORMAT === 'json') {
    formatters = {
      level() {
        return {};
      },
      bindings() {
        return {};
      },
    };
  }

  const pinoOptions = {
    timestamp,
    formatters,
  };

  if (!isProd && !isJest) {
    pinoOptions.transport = {
      target: 'pino-pretty',
      options: {
        colorize: true,
        include: 'ms',
        singleLine: true,
        messageFormat: '',
        customPrettifiers: {},
      },
    };
  }

  return originalPino(pinoOptions);
})();

/**
 * Selects a color for a debug namespace
 * @param {String} namespace The namespace string for the debug instance to be colored
 * @return {Number|String} An ANSI color code for the given namespace
 * @api private
 */
function selectColor(namespace) {
  let hash = 0;

  for (let i = 0; i < namespace.length; i++) {
    hash = (hash << 5) - hash + namespace.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }

  return pinoDebug.colors[Math.abs(hash) % pinoDebug.colors.length];
}

function extend(namespace) {
  const newNamespace = `${this.namespace}:${namespace}`;
  const logger = pinoDebug(newNamespace);

  return logger;
}

/**
 * Enables a debug mode by namespaces. This can include modes
 * separated by a colon and wildcards.
 *
 * @param {String} namespaces
 * @api public
 */
const enable = namespaces => {
  pinoDebug.namespaces = namespaces;
  pinoDebug.enabledNamespaces = [];
  pinoDebug.skipNamespaces = [];

  const namespacesSplit = (
    typeof namespaces === 'string' ? namespaces : ''
  ).split(/[\s,]+/);

  for (let i = 0; i < namespacesSplit.length; i++) {
    if (!namespacesSplit[i]) {
      // ignore empty strings
      continue;
    }

    const namespace = namespacesSplit[i].replace(/\*/g, '.*?');

    if (namespace[0] === '-') {
      pinoDebug.skipNamespaces.push(new RegExp('^' + namespace.slice(1) + '$'));
    } else {
      pinoDebug.enabledNamespaces.push(new RegExp('^' + namespace + '$'));
    }
  }
};

/**
 * Disable debug output.
 *
 * @return {String} namespaces
 * @api public
 */
const disable = () => {
  const namespaces = [
    ...pinoDebug.enabledNamespaces.map(toNamespace),
    ...pinoDebug.skipNamespaces
      .map(toNamespace)
      .map(namespace => '-' + namespace),
  ].join(',');
  pinoDebug.enable('');
  return namespaces;
};

/**
 * Returns true if the given mode name is enabled, false otherwise.
 *
 * @param {String} name
 * @return {Boolean}
 * @api public
 */
const enabled = name => {
  if (name[name.length - 1] === '*') return true;

  for (let i = 0; i < pinoDebug.skipNamespaces.length; i++) {
    if (pinoDebug.skipNamespaces[i].test(name)) return false;
  }

  for (let i = 0; i < pinoDebug.enabledNamespaces.length; i++) {
    if (pinoDebug.enabledNamespaces[i].test(name)) return true;
  }

  return false;
};

/**
 * Convert regexp to namespace
 *
 * @param {RegExp} regxep
 * @return {String} namespace
 * @api private
 */
const toNamespace = regexp => {
  return regexp
    .toString()
    .substring(2, regexp.toString().length - 2)
    .replace(/\.\*\?$/, '*');
};

const load = () => {
  if (isServer || isMiddleware) {
    return env.DEBUG;
  } else {
    const loadClientDebugs = [];
    try {
      const url = new URL(location.href);
      if (url.searchParams.has('debug'))
        loadClientDebugs.push(url.searchParams.get('debug'));
      if (localStorage.getItem('debug'))
        loadClientDebugs.push(localStorage.getItem('debug'));
      // eslint-disable-next-line
    } catch (_) {}

    return loadClientDebugs.join(',');
  }
};

/**
 * Create a debugger with the given `namespace`.
 *
 * @param {String} namespace
 * @return {Function}
 * @api public
 */
export const pinoDebug = namespace => {
  if (!namespace) throw new Error('namespace is required');

  let enableOverride = null;
  let namespacesCache;
  let enabledCache;

  const pino = (...args) => {
    if (!pino.enabled) return;

    if (isServer && env.DEBUG_FORMAT === 'json') {
      // const [isoTime, namespace, ...textArray] = args[0]?.split(' ') || [];
      const labels = namespace?.split(':') || [];
      const data = args[args.length - 1];
      const [, requestId] =
        labels[labels.length - 1].match(/^request_id-(.*)$/) || [];

      const output = {
        severity: severityMap[labels[1]] || severity.DEBUG,
        message: args[0],
        labels: {
          namespace,
          topNamespace: labels[0],
          functionName: labels[2],
          version: env.TAG_NAME || env.BRANCH_NAME || 'local',
          sha: env.SHORT_SHA,
        },
      };
      if (data) {
        output.data = data;
      }
      if (requestId) {
        output.labels.requestId = requestId;
      }
      mainPino.info(output);
      return;
    }

    mainPino.info(
      `${namespace} ${args
        .map(arg => {
          const type = typeof arg;
          if (type === 'string') return '%s';
          if (type === 'number') return '%d';
          return '%o';
        })
        .join(' ')}`,
      ...args
    );
  };

  pino.namespace = namespace;
  // pino.useColors = pinoDebug.useColors();
  // pino.color = pinoDebug.selectColor(namespace);
  pino.extend = extend;

  Object.defineProperty(pino, 'enabled', {
    enumerable: true,
    configurable: false,
    get: () => {
      if (enableOverride !== null) {
        return enableOverride;
      }
      if (namespacesCache !== pinoDebug.namespaces) {
        namespacesCache = pinoDebug.namespaces;
        enabledCache = pinoDebug.enabled(namespace);
      }

      return enabledCache;
    },
    set: v => {
      enableOverride = v;
    },
  });

  return pino;
};
pinoDebug.enabledNamespaces = [];
pinoDebug.skipNamespaces = [];
pinoDebug.disable = disable;
pinoDebug.enable = enable;
pinoDebug.enabled = enabled;
pinoDebug.load = load;
pinoDebug.selectColor = selectColor;

pinoDebug.enable(pinoDebug.load());
