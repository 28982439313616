import { breakpoint } from '../style/variables.js';

const ERROR_RECOVERY_SCRIPT_ID = 'swag-error-recovery';
const ERROR_RECOVERY_TOAST_ID = 'swag-error-recovery-toast';

export const errorRecovery = ({
  enableErrorRecovery,
  errorRecoveryResponseTimeout = 10000,
  maxErrorResourceCount = 3,
} = {}) => {
  if (!enableErrorRecovery) return '';

  return /* html */ `
    <script type="text/javascript" id="${ERROR_RECOVERY_SCRIPT_ID}">
      (function() {
        const MAX_ERROR_RESOURCE_COUNT = ${maxErrorResourceCount};
        const ERROR_RECOVERY_RESPONSE_TIMEOUT_MSEC = ${errorRecoveryResponseTimeout};
        let errorResourceCount = 0;
        let isReloading = false;
        const url = new URL(window.location.href);

        window.addEventListener(
          'error',
          e => {
            const { message, target } = e;
            if (target.tagName) {
              const tag = target.tagName.toLowerCase();
              let targetUrl = {};
              try{
                targetUrl = new URL(target.src);
              } catch(_) {}

              if (
                tag === 'script' &&
                targetUrl.hostname === location.hostname &&
                /\\.js$/.test(targetUrl.href)
              ) {
                console.log('Script resource error: ', target.src);
                errorResourceCount += 1;
                if (errorResourceCount >= MAX_ERROR_RESOURCE_COUNT && !isReloading) {
                  console.log('Show toast');
                  isReloading = true;
                  showErrorRecoveryToast();
                }
              }
            }
          },
          true
        );

        function reloadPage() {
          url.searchParams.set('clear', 1);
          history.replaceState(null, '', url.href);
          location.reload(true);
        }

        function createErrorRecoveryToast() {
          const width = window.innerWidth;
          let toastStyleCssText = 'position: fixed; z-index: 999; max-width: 100%;';
          if (width <= ${breakpoint.mobile}) toastStyleCssText += 'width: 100%; bottom: 69px; padding: 0 20px;';
          else if (${breakpoint.mobile} < width && width <= ${breakpoint.tablet}) toastStyleCssText += 'width: 528px; bottom: 20px; left: 50%; transform: translateX(-50%);';
          else toastStyleCssText += 'width: 320px; bottom: 20px; left: 20px;';

          const wording = window.__ERROR_RECOVERY_WORDING__ || 'No internet connection';

          const toast = document.createElement('div');
          toast.id = '${ERROR_RECOVERY_TOAST_ID}';
          toast.style.cssText = toastStyleCssText;
          const wrapper = document.createElement('div');
          wrapper.style.cssText = 'background: #474747; border-radius: 4px; padding: 12px;';
          const info = document.createElement('div');
          info.style.cssText = 'display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 12px; font-size: 14px;'
          const leftInfo = document.createElement('div');
          leftInfo.style.cssText = 'display: flex; align-items: flex-start;';
          const infoIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
          infoIcon.setAttributeNS(null, 'width', '18');
          infoIcon.setAttributeNS(null, 'height', '18');
          infoIcon.setAttributeNS(null, 'fill', 'none');
          infoIcon.innerHTML = '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.99961 15.3C8.50326 15.3 8.09961 14.8964 8.09961 14.4C8.09961 13.9037 8.50326 13.5 8.99961 13.5C9.49551 13.5 9.89961 13.9037 9.89961 14.4C9.89961 14.8964 9.49551 15.3 8.99961 15.3Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.94648 11.7C8.57321 11.7 8.27148 11.448 8.27148 11.1375V3.26251C8.27148 2.95201 8.57321 2.70001 8.94648 2.70001C9.31976 2.70001 9.62148 2.95201 9.62148 3.26251V11.1375C9.62148 11.448 9.31976 11.7 8.94648 11.7Z" fill="white"/><path d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z" stroke="white"/>';
          leftInfo.appendChild(infoIcon);
          const text = document.createElement('span');
          text.style.cssText = 'padding-left: 8px;';
          text.textContent = wording;
          leftInfo.appendChild(text);
          const rightInfo = document.createElement('div');
          rightInfo.style.cssText = 'cursor: pointer; display: flex; flex-direction: row-reverse; align-items: center; width: 24px; height: 24px;';
          const closeIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
          closeIcon.setAttributeNS(null, 'width', '16');
          closeIcon.setAttributeNS(null, 'height', '16');
          closeIcon.setAttributeNS(null, 'fill', 'none');
          closeIcon.innerHTML = '<path d="M3.2002 3.20001L12.8002 12.8" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.7998 3.20001L3.19981 12.8" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>';
          rightInfo.appendChild(closeIcon);
          rightInfo.addEventListener('click', () => {
            const toast = document.getElementById('${ERROR_RECOVERY_TOAST_ID}');
            toast.style.display = 'none';
          })
          info.appendChild(leftInfo);
          info.appendChild(rightInfo);
          wrapper.appendChild(info);
          const button = document.createElement('button');
          button.style.cssText = 'border:none; border-radius: 4px; background: #00D2BE; width: 100%; height:28px; text-align: center; font-size: 14px;';
          button.textContent = 'Retry';
          button.addEventListener('click', reloadPage);
          wrapper.appendChild(button);
          toast.appendChild(wrapper);
          document.body.appendChild(toast);
        }

        function showErrorRecoveryToast() {
          const toast = document.getElementById('${ERROR_RECOVERY_TOAST_ID}');
          if (toast) {
            toast.style.display = 'block';
            return;
          }

          if (document.body) {
            createErrorRecoveryToast();
          } else {
            // this script is injected inside the head tag
            // when errors happen, the document.body might not exist
            // use MutationObserver to wait for the body element shows up
            const observer = new MutationObserver(function() {
              if (document.body) {
                createErrorRecoveryToast();
                observer.disconnect();
              }
            });
            observer.observe(document.documentElement, {childList: true});
          }
        }

        // make sure the first client js should clear this timeout
        window.__INDEX_JS_RESPONSE_TIMEOUT__ = setTimeout(() => {
          console.log('Show toast because timeout')
          showErrorRecoveryToast();
        }, ERROR_RECOVERY_RESPONSE_TIMEOUT_MSEC)

        window.__showErrorRecoveryToast__ = showErrorRecoveryToast;
      })()
    </script>
    `;
};

export const clearErrorRecovery = () => {
  if (window.__INDEX_JS_RESPONSE_TIMEOUT__)
    clearTimeout(window.__INDEX_JS_RESPONSE_TIMEOUT__);

  const toast = document.getElementById(ERROR_RECOVERY_TOAST_ID);
  if (toast) toast.style.display = 'none';
};
