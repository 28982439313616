// getResourceUrl.js
'use strict';
import { API, WATCH, PUBLIC, RSS_FEED } from './resourceUrlTypeConstants.js';
import { getIsInBrowserMainThread, getIsInNode } from './getJsEnvironment.js';

const isServer = getIsInNode();
const isBrowserMainThread = getIsInBrowserMainThread();
const FALLBACK_URL = 'https://api.swag.live';

const PREFIX_MAP = {
  [API]:
    process.env.SSR_API_URL_PREFIX ||
    process.env.API_URL_PREFIX ||
    FALLBACK_URL,
  [WATCH]: process.env.WATCH_URL_PREFIX,
  [PUBLIC]: process.env.PUBLIC_URL_PREFIX,
  [RSS_FEED]: process.env.RSS_FEED_URL_PREFIX,
};

const getHostname = () => 'swag.live';

/**
 * Get api endpoint core
 * @param {String} {[path = '/']} API Suffix Path
 * @param {String} {[endpoint = '/']} API Endpoint
 * @param {String} {resourceType} API Resource Type
 * @return {URL instance} will return URL instance.
 */
const getResourceUrlCore = ({ endpoint = '/', path, resourceType = API }) => {
  let subDomain = PREFIX_MAP[resourceType] || PREFIX_MAP[API];

  // Note: isServer in service worker is true, so globalThis need to use optional chaining
  if (isServer && globalThis?.__RESOURCE_URL__) {
    subDomain =
      globalThis.__RESOURCE_URL__[resourceType] ||
      globalThis.__RESOURCE_URL__[API] ||
      subDomain;
  }
  if (isBrowserMainThread) {
    subDomain =
      window.__RESOURCE_URL__?.[resourceType] ||
      window.__RESOURCE_URL__?.[API] ||
      subDomain;
  }

  const formattedPath = path
    ? path.replace(/^\//, '').replace(/[/]*$/, '/')
    : '';
  const formattedEndpoint = endpoint.replace(/^\//, '');

  let isPrefixHasProtocol = false;
  try {
    isPrefixHasProtocol = !!new URL(subDomain).protocol;
  } catch (error) {
    //
  }

  if (isPrefixHasProtocol) {
    // ex: http://proxy.web
    return new URL(formattedEndpoint, new URL(formattedPath, subDomain));
  } else {
    const domain = getHostname();
    return new URL(
      formattedEndpoint,
      new URL(formattedPath, `https://${subDomain}.${domain}`)
    );
  }
};

/**
 * Get api endpoint
 * @param {String} {[path = '/']} API Suffix Path
 * @param {String} {[endpoint = '/']} API Endpoint
 * @param {String} {[resourceType = API]} API Resource Type
 * @param {String} {origin} customize origin string
 * @return {URL instance} will return URL instance.
 */
const getResourceUrl = ({
  path = '',
  endpoint,
  resourceType = API,
  origin,
}) => {
  const url = getResourceUrlCore({ endpoint, path, resourceType });
  if (origin) {
    url.searchParams.set('_o', origin);
  } else if (
    isBrowserMainThread &&
    API === resourceType &&
    // localhost is for skip unit test env
    !['swag.live', 'localhost'].includes(window.location.hostname)
  ) {
    // try to imitate `Vary` header to escape CORS.
    url.searchParams.set('_o', window.location.origin);
  }
  return url;
};

export default getResourceUrl;
