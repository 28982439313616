// polyfill.js
'use strict';
import URLSearchParams from '@ungap/url-search-params';
import smoothscroll from 'smoothscroll-polyfill';
import '../resource/intersectionObserver.js';
import AudioRecorder from 'audio-recorder-polyfill';
import '../resource/canvasToBlob.js';
import ResizeObserver from 'resize-observer-polyfill';

if (!window.URLSearchParams) {
  window.URLSearchParams = URLSearchParams;
}

if (window) {
  smoothscroll.polyfill();
}

if (window) {
  window.MediaRecorder = AudioRecorder;
}

if (!window.requestIdleCallback) {
  window.requestIdleCallback = window.setTimeout;
}

if (!window.cancelIdleCallback) {
  window.cancelIdleCallback = function (id) {
    window.clearTimeout(id);
  };
}

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

if (!JSON.safeStringify) {
  // safely handles circular references
  // ref: https://stackoverflow.com/a/11616993
  JSON.safeStringify = (object, indent = 2) => {
    let cache = [];
    const result = JSON.stringify(
      object,
      (key, value) => {
        let result = value;
        if (typeof value === 'object' && value !== null) {
          if (cache.includes(value)) {
            result = '[Duplicate]';
          } else {
            cache.push(value); // Store value in our collection
            if (Object.keys(value).length > 128) {
              result = `[Object keys: ${Object.keys(value).length}]`;
            }
          }
        }
        return result;
      },
      indent
    );
    cache = null;
    return result;
  };
}
